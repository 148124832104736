import React, { useState, useEffect} from 'react';
import './AlliancePageStyle.css';
import allianceLogo from './Wojownicy.png';
import recruitmentImage from './alert.png'; 
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import muzyka from './muzyka.mp3';

function AlliancePage() {
  
    const [showMembers, setShowMembers] = useState(false);  
    const [showRecruitment, setShowRecruitment] = useState(false);
    const [displayedRecruitmentText, setDisplayedRecruitmentText] = useState('');
    const [showAbout, setShowAbout] = useState(false);
    const [displayedAboutText, setDisplayedAboutText] = useState("");
    const [displayedWarText, setDisplayedWarText] = useState("");
    const [showWar, setShowWar] = useState(false);
    const warText = "Na chwilę obecną 'NAS' nie prowadzi wojen przeciwko innemu sojuszowi.";
    const [showDiplomacy, setShowDiplomacy] = useState(false);
    const [displayedDiplomacyText, setDisplayedDiplomacyText] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const recruitmentText = "Rekrutacja na chwilę obecną jest zamknięta.";  
    const aboutText = "Jesteśmy grupą ludzi, którzy świetnie bawią się podczas grania w Ogame. Gramy na uniwersum Thuban.";
    const diplomacyText = "W sprawach dyplomacji proszę kontaktować się z Szlachetna ZAGRYCHA";
    const [page, setPage] = useState(1);
    const membersPerPage = isMobile ? 5 :10;
    const [playMusic, setPlayMusic] = useState(false);

    const handleMusicToggle = () => {
        setPlayMusic(prev => !prev);
    };
      
    const nextPage = () => {
        if (page < Math.ceil(rows.length / membersPerPage)) {
             setPage(page + 1);
        }
    };
  
    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

   
    useEffect(() => {
      if (showRecruitment && displayedRecruitmentText !== recruitmentText) {
          const timer = setInterval(() => {
              setDisplayedRecruitmentText(prev => recruitmentText.slice(0, prev.length + 1));
          }, 25);
  
          return () => clearInterval(timer);
      } else if (!showRecruitment) {
          setDisplayedRecruitmentText("");
      }
  }, [showRecruitment, displayedRecruitmentText, recruitmentText]);
  
    useEffect(() => {
        if (showAbout && displayedAboutText !== aboutText) {
            const timer = setInterval(() => {
                setDisplayedAboutText(prev => aboutText.slice(0, prev.length + 1));
            }, 25);
    
            return () => clearInterval(timer);
        } else if (!showAbout) {
            setDisplayedAboutText("");
        }
    }, [showAbout, displayedAboutText, aboutText]);

    useEffect(() => {
        if (showDiplomacy && displayedDiplomacyText !== diplomacyText) {
            const timer = setInterval(() => {
                setDisplayedDiplomacyText(prev => diplomacyText.slice(0, prev.length + 1));
            }, 25);

            return () => clearInterval(timer);
        } else if (!showDiplomacy) {
            setDisplayedDiplomacyText("");
        }
    }, [showDiplomacy, displayedDiplomacyText, diplomacyText]);

    useEffect(() => {
      if (showWar && displayedWarText !== warText) {
          const timer = setInterval(() => {
              setDisplayedWarText(prev => warText.slice(0, prev.length + 1));
          }, 25);

          return () => clearInterval(timer);
      } else if (!showWar) {
          setDisplayedWarText("");
      }
  }, [showWar, displayedWarText, warText]);

  useEffect(() => {
    if (showRecruitment || showAbout || showDiplomacy || showWar) {
        document.body.classList.add('no-scroll');
        } else {
        document.body.classList.remove('no-scroll');
        }
    }, [showRecruitment, showAbout, showDiplomacy, showWar]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    

  const toggleMembers = () => {
    setShowMembers(prev => !prev);
    setShowRecruitment(false);
    setShowAbout(false);
    setShowWar(false);
    setShowDiplomacy(false);
};

const toggleRecruitment = () => {
    setShowRecruitment(prev => !prev);
    setShowMembers(false);
    setShowAbout(false);
    setShowWar(false);
    setShowDiplomacy(false);
};

const toggleWar = () => {
    setShowWar(prev => !prev);
    setShowRecruitment(false);
    setShowMembers(false);
    setShowAbout(false);
    setShowDiplomacy(false);
};

const toggleAbout = () => {
    setShowAbout(prev => !prev);
    setShowRecruitment(false);
    setShowMembers(false);
    setShowWar(false);
    setShowDiplomacy(false);
};

const toggleDiplomacy = () => {
    setShowDiplomacy(prev => !prev);
    setShowRecruitment(false);
    setShowMembers(false);
    setShowAbout(false);
    setShowWar(false);
};

    const rows = [
      { nick: 'Algo'},
      { nick: 'Anubis'},
      { nick: 'axa'},
      { nick: 'Bubek'},
      { nick: 'Dobby'},
      { nick: 'Gamer'},
      { nick: 'Gilgameshu'},
      { nick: 'Gordon'},
      { nick: 'GreenWolf'},
      { nick: 'Inside_PL'},
      { nick: 'Kristoff'},
      { nick: 'Kronos'},
      { nick: 'MagnumGK'},
      { nick: 'Monster'},
      { nick: 'Penn'},
      { nick: 'Reskato'},
      { nick: 'Roldamon'},
      { nick: 'Salazar'},
      { nick: 'shaki'},
      { nick: 'Szlachetna ZAGRYCHA'},
      { nick: 'TariVonRan'},
      { nick: 'Tomasteam'},
    ];
  
    return (
        <div className="alliance-page">
          <div className="alliance-header">Nagły Atak Spawacza</div>
          <div className="alliance-logo">
          <img src={allianceLogo} alt="Logo Sojuszu" />
          </div>
          <nav className="navbar">
          <button className="nav-btn" onClick={toggleWar}>Wojny</button>
          <button className="nav-btn" onClick={toggleRecruitment}>Rekrutacja</button>
            <button className="nav-btn" onClick={toggleMembers}>Członkowie</button>
            <button className="nav-btn" onClick={toggleDiplomacy}>Dyplomacja</button>
          <button className="nav-btn" onClick={toggleAbout}>O Sojuszu</button>
        </nav>
        <div>
            {playMusic && (
                <audio autoPlay loop>
                    <source src={muzyka} type="audio/mpeg" />
                </audio>
            )}

        <button onClick={handleMusicToggle} className="music-toggle-button">
            {playMusic ? <i className="fas fa-volume-mute"></i> : <i className="fas fa-volume-up"></i>}
        </button>
        </div>
        {showMembers && (
                <TableContainer component={Paper} className={`members-table ${showMembers ? 'show-members' : ''} burningEffectBlue`}>
                    <Table>
                        <TableHead>
                            {/* Tu możesz dodać nagłówki tabeli, jeśli są potrzebne */}
                        </TableHead>
                        <TableBody>
                            {rows.slice((page - 1) * membersPerPage, page * membersPerPage).map((row, index) => (
                                <TableRow
                                    key={index}
                                    className="animateRow"
                                    style={{ animationDelay: `${0.05 * (index + 1)}s` }}
                                >
                                    <TableCell>{row.nick}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className="pagination-controls">
                        <button onClick={prevPage} disabled={page === 1}>
                            Poprzednia
                        </button>
                        <span>
                            Strona {page} z {Math.ceil(rows.length / membersPerPage)}
                        </span>
                        <button onClick={nextPage} disabled={page === Math.ceil(rows.length / membersPerPage)}>
                            Następna
                        </button>
                    </div>
                </TableContainer>
            )}

    {showDiplomacy && (
      <div className="diplomacy-overlay">
          <div className="diplomacy-content">
              <p>{displayedDiplomacyText}</p>
          </div>
      </div>
    )}
   {showRecruitment && (
    <div className="recruitment-overlay">
    <div className="recruitment-content">
      <img src={recruitmentImage} alt="Recruitment" className="recruitment-image"/>
      <p>{displayedRecruitmentText}</p>
    </div>
    </div>
    )}
    {showAbout && (
    <div className="about-overlay">
        <div className="about-content">
            <p>{displayedAboutText}</p>
        </div>
    </div>
    )}
     <div className={`alliance-page ${showWar ? 'show-war' : ''}`}>
                {showWar && (
                    <div className="war-section">
                        <p>{displayedWarText}</p>
                    </div>
                )}
            </div>
    </div>
    );
}

export default AlliancePage;
import React from 'react';
import './Background.css';
import backgroundImage from './backgroundImage.jpg';
import mobileBackgroundImage from './mobile.jpg';

function Background() {
  // Sprawdź szerokość okna przeglądarki, aby określić, czy używać tła mobilnego
  const isMobile = window.innerWidth <= 768; // Możesz dostosować wartość 768 do swoich potrzeb

  return (
    <div className="backgroundContainer" style={{ backgroundImage: `url(${isMobile ? mobileBackgroundImage : backgroundImage})` }}></div>
  );
}

export default Background;

import AlliancePage from './AlliancePage/AlliancePage';
import Background from './Background';

function App() {
  return (
    <div className="App">
      <Background />
      <header className="App-header">
      </header>
      <AlliancePage />
    </div>
  );
}

export default App;
